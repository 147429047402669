<template>
  <v-container fluid class="pa-0 mb-6">
    <v-row justify="center">
      <v-col cols="12">
        <v-img
          height="634px"
          class="align-center"
          :src="get_image.img_0"
        >
          <v-row>
            <v-col
              lg="10"
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
            >
              <div
                @click="editar('about.title')"
                v-if="!$vuetify.breakpoint.smAndDown"
                v-html="$t('about.title')"
                class="textoImagenPc"
              ></div>
              <div
                v-else
                @click="editar('about.title')"
                v-html="$t('about.title')"
                class="textoImagenMobile"
              ></div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col
        @click="editar('about.section_one')"
        cols="12"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'titulo text-center'
            : 'tituloSecundario text-center'
        "
        :style="
          'margin-top:' +
          ($vuetify.breakpoint.smAndDown ? '79px' : '172px') +
          ';'
        "
      >
        {{ $t("about.section_one") }}
      </v-col>
      <v-col lg="10" md="10" sm="11" cols="11" style="margin-top: 90px">
        <v-row justify="center">
          <v-col
            v-for="(card, index) in $t('about.so_card_about')"
            :key="index"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            style="margin-bottom: 23px"
          >
            <card-about
              :numElement="index"
              :titulo="card.titulo"
              :img="card.img"
              :detalle="card.detalle"
            />
          </v-col>
          <v-col cols="12" style="margin: 80px 0 40px 0">
            <omni-about :img="get_image.img_1" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardAbout from "@/components/about/CardAbout.vue";
import OmniAbout from "@/components/about/OmniAbout.vue";
import { ABOUT_US } from '@/assets/img/images';

export default {
  nuxtI18n: {
    paths: {
      en: "/about-us",
      es: "/sobre-nosotros",
    },
  },
  metaInfo() {
    return {
      title: this.$t("about.seo.title"),
      meta: [
        { name: "descripción", content: this.$t("about.seo.description") },
        { name: "keywords", content: this.$t("about.seo.keywords") },
      ],
    };
  },
  components: {
    CardAbout,
    OmniAbout,
  },
  data() {
    return {};
  },
  computed: {
    get_image() {
      return ABOUT_US;
    },
  },
};
</script>
