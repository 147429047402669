<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="mb-9">
      <v-col
        cols="12"
        class="titulo text-center"
        @click="editar('about.headquarters')"
      >
        {{$t('about.headquarters')}}
      </v-col>
      <v-col cols="12">
        <v-img
          :aspect-ratio="5/5"
          :src="img"
        />
      </v-col>
      <v-col @click="editar('about.h_details')" cols="10" class="parrafoContact">
        {{$t('about.h_details')}}
      </v-col>
      <v-col cols="10" class="textoSimple ml-0">
        <div @click="editar('about.h_unites')" v-html="$t('about.h_unites')"> </div>
      </v-col>
      <v-col cols="10" class="textoSimple ml-0">
        <div @click="editar('about.h_mex')" v-html="$t('about.h_mex')"> </div>
      </v-col>
    </v-row>
    <v-card v-else elevation="0" color="#F2F4F6">
      <v-img
        :aspect-ratio="16/7"
        max-height="500px"
        contain
        position="right"
        :src="img">
        <v-row justify="center" class="mt-4">
          <v-col @click="editar('about.headquarters')" lg="8" md="10" cols="10" class="subTituloSimple">
            {{$t('about.headquarters')}}
          </v-col>
          <v-col lg="8" md="10" cols="10" class="parrafoContact pl-4 mt-1">
            <div @click="editar('about.h_details')" style="max-width: 237px;">
              {{$t('about.h_details')}}
            </div>
            <div @click="editar('about.h_unites')" v-html="$t('about.h_unites')" class="mt-4"></div>
            <div @click="editar('about.h_mex')" v-html="$t('about.h_mex')" class="mt-4"></div>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  }
}
</script>
