<template>
  <v-container fluid class="pa-0">
    <v-row class="marco" align="center" v-if="$vuetify.breakpoint.smAndDown">
      <v-col cols="7" class="pa-0">
        <v-img
          :aspect-ratio="10/10"
          :src="img"
        />
      </v-col>
      <v-col @click="editar('about.so_card_about', true, numElement, 'titulo')" cols="5" class="subTituloMobile pa-0 pl-3">
        {{titulo}}
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col @click="editar('about.so_card_about', true, numElement, 'detalle')" cols="11" class="textoMobil" v-html="detalle">
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.smAndDown">
      <v-col cols="12">
        <div @click="editar('about.so_card_about', true, numElement, 'titulo')" class="subTituloMobile">
          {{titulo}}:
        </div>
      </v-col>
      <v-col cols="8">
        <div @click="editar('about.so_card_about', true, numElement, 'detalle')" class="textoSimple ml-0" v-html="detalle">
        </div>
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-img
          :aspect-ratio="10/10"
          :src="img"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    detalle: {
      type: String,
      required: true,
    },
    numElement: {
      type: Number,
      required: true,
    }
  },
}
</script>
